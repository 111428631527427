import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { path } from "../../path";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import "./ContestEdit.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ContestEdit = () => {
  const { contestId } = useParams();
  const [contest, setContest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const grid = [];
    axios
      .get(`${path}/api/response/getContestId/${contestId}`)
      .then((response) => {
        const data = response.data?.data.responses.sections || [];
        data.forEach((section, sectionIndex) => {
          grid[sectionIndex] = [];
          section.fields.forEach((field, fieldIndex) => {
            if (field.fieldType === "date") {
              field.response = moment(
                field.response,
                "YYYY-MM-DDTHH:mm:ss.SSSZ"
              ).format("DD-MM-YYYY HH:mm");
              field.response = convertDateTime(field.response);
            }
            if (field.fieldType === "chips") {
              grid[sectionIndex][fieldIndex] = field.response;
            } else {
              grid[sectionIndex][fieldIndex] = field.response;
            }
            if (field.fieldType === "file") {
              setImage(field.response);
            }
            if (field.fieldType === "search") {
              setSelectedUsers(field.response);
            }
          });
        });
        setRules(grid);
        setContest(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the contest!", error);
      });
  }, [contestId]);
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      // handleUpload()
    }
  };
  // Search Logic

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm.length >= 1) {
        fetchUser();
      } else {
        // fetchRecentUser();
        // setRecentSearchesDisplay(true);
      }
    }, 750);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const fetchUser = async () => {
    // Call your API here with the searchTerm
    // For demo purposes, mock data is used
    const response = await axios.post(
      `${path}/specificUser?search=${searchTerm}`
    );

    console.log(response, "response");
    setSearchResults(response.data.user);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserSelect = (user) => {
    setSelectedUsers([...selectedUsers, user]);
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleChipRemove = async (user) => {
    const res = await axios.post(`${path}/api/response/removeJudge`, {
      userId: user._id,
      contestId: contestId,
    });
    console.log(res);
    setSelectedUsers(
      selectedUsers.filter((selectedUser) => selectedUser._id !== user._id)
    );
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    const res = await axios.post(`${path}/contest/ImageLink`, formData);
    console.log(res.data.url);
    setImage(res.data.url);
    alert("Cover photo uploaded successfully!");
  };

  function convertDateTime(input) {
    // Split the input into date and time components
    const [date, time] = input.split(" ");

    // Split the date into day, month, and year
    const [day, month, year] = date.split("-");

    // Reformat the date and combine with time
    const formattedDate = `${year}-${month}-${day}T${time}`;

    return formattedDate;
  }
  const handleAddRule = (sectionIndex, fieldIndex, e) => {
    e.preventDefault();

    const updatedContest = { ...contest };
    updatedContest.data.responses.sections[sectionIndex].fields[
      fieldIndex
    ].response.push("");
    setContest(updatedContest);
  };

  const handleDeleteRule = (sectionIndex, fieldIndex, index) => {
    const updatedContest = { ...contest };

    const updatedRules = updatedContest.data.responses.sections[
      sectionIndex
    ].fields[fieldIndex].response.filter((_, i) => i !== index);

    updatedContest.data.responses.sections[sectionIndex].fields[
      fieldIndex
    ].response = updatedRules;
    setContest(updatedContest);
  };

  const handleRuleChange = (sectionIndex, fieldIndex, index, newValue) => {
    const updatedContest = { ...contest };
    const updatedRules = updatedContest.data.responses.sections[
      sectionIndex
    ].fields[fieldIndex].response.map((rule, i) =>
      i === index ? newValue : rule
    );

    updatedContest.data.responses.sections[sectionIndex].fields[
      fieldIndex
    ].response = updatedRules;
    setContest(updatedContest);
  };
  useEffect(() => {
    console.log(rules);
  }, [rules]);

  const handleChange = (sectionIndex, fieldIndex, e) => {
    const { value, type } = e.target;
    const updatedContest = { ...contest };

    if (type === "checkbox") {
      // Checkbox handling
    } else if (type === "radio") {
      // Chips handling

      updatedContest.data.responses.sections[sectionIndex].fields[
        fieldIndex
      ].response = value;
    } else {
      updatedContest.data.responses.sections[sectionIndex].fields[
        fieldIndex
      ].response = value;
    }

    setContest(updatedContest);
    console.log(updatedContest);
  };
  const convertTheDate = (input) => {
    const [date, time] = input.split("T");

    // Combine date and time with " at "
    const formattedDate = `${date} at ${time}`;

    return formattedDate;
  };

  const handleSubmit = () => {
    // e.preventDefault();
    console.log("Submitting..");
    const formattedContest = { ...contest };
    formattedContest.data.responses.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.fieldType === "date") {
          field.response = convertTheDate(field.response);
        }
        if (section.title === "Section_A" && field.fieldType === "file") {
          field.response = image;
        }
        if (field.fieldType === "search") {
          field.response = selectedUsers;
        }
      });
    });

    axios
      .put(`${path}/api/response/updateContest/${contestId}`, formattedContest)
      .then((response) => {
        alert("Contest updated successfully");
        setContest(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error updating the contest!", error);
      });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex-col px-[20%] justify-center  bg-white">
      <div className="flex w-full fixed items-center  bg-white mb-4 h-20 space-x-5 overflow-hidden ">
        <Link to={"/marketingAdmin"}>
          <div className="flex items-center ">
            <img
              src="https://w7.pngwing.com/pngs/848/762/png-transparent-computer-icons-home-house-home-angle-building-rectangle-thumbnail.png"
              className="h-6 mr-4"
              alt="home"
            />
            <h3>Home</h3>
          </div>
        </Link>
      </div>

      <form className="space-y-4 pt-20">
        <h1 className="text-xl font-bold  mb-4 text-center">Edit Contest</h1>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Contest Title
          </label>
          <input
            type="text"
            name="title"
            value={contest.data.responses.title}
            onChange={(e) =>
              setContest({
                ...contest,
                data: {
                  ...contest.data,
                  responses: {
                    ...contest.data.responses,
                    title: e.target.value,
                  },
                },
              })
            }
            className="mt-1 block w-full rounded-md border border-blue-800 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        {contest.data.responses.sections.map((section, sectionIndex) => (
          <div key={section._id} className="border p-4 rounded-md my-4">
            <h2 className="text-xl font-semibold mb-2">{section.title}</h2>
            {/* <p>{section.description}</p> */}
            <input
              type="text"
              value={section.description}
              onChange={(e) => {
                if (section.title === "Skills") {
                  // Apply regex to transform input: lowercase and replace spaces with underscores
                  const normalizedValue = e.target.value
                    .trim()
                    .toLowerCase()
                    .replace(/\s+/g, "_");

                  setContest((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data,
                      responses: {
                        ...prev.data.responses,
                        sections: prev.data.responses.sections.map(
                          (sec, idx) => {
                            if (idx === sectionIndex) {
                              return { ...sec, description: normalizedValue };
                            }
                            return sec;
                          }
                        ),
                      },
                    },
                  }));
                }
              }}
              className={`mt-1 block w-full rounded-md border ${section.title === "Skill" ? "border-blue-800" : "border-gray-300"} shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
              disabled={section.title !== "Skills"}
            />
            {section.fields.map((field, fieldIndex) => (
              <div key={field._id} className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  {field.title}
                </label>
                {field.fieldType === "input" && (
                  <input
                    type="text"
                    value={field.response}
                    onChange={(e) => {
                      let updatedValue = e.target.value;
                      if (field.title === "Sub_Skill") {
                        // Apply normalization for Sub_Skill
                        updatedValue = updatedValue
                          .trim()
                          .toLowerCase()
                          .replace(/\s+/g, "_");
                      }
                      handleChange(sectionIndex, fieldIndex, {
                        target: { value: updatedValue },
                      });
                    }}
                    className="mt-1 block w-full rounded-md border border-blue-800 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                )}
                {field.fieldType === "file" && (
                  <div className="flex flex-row">
                    <img width={100} height={100} src={image} />
                    <div className="flex flex-col">
                      <input type="file" onChange={handleFileChange} />
                      <button
                        onClick={handleUpload}
                        className="bg-teal-600 text-white w-fit ml-2 mt-2">
                        Upload
                      </button>
                    </div>
                  </div>
                )}
                {field.fieldType === "textarea" && (
                  <textarea
                    value={field.response}
                    onChange={(e) => handleChange(sectionIndex, fieldIndex, e)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                )}
                {field.fieldType === "date" && (
                  <input
                    type="datetime-local"
                    value={field.response.slice(0, 16)}
                    onChange={(e) => handleChange(sectionIndex, fieldIndex, e)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                )}
                {field.fieldType === "radio" &&
                  field.options.map((option) => (
                    <div key={option} className="flex items-center mt-1">
                      <input
                        type="radio"
                        name={`${field._id}`}
                        value={option}
                        checked={field.response === option}
                        onChange={(e) =>
                          handleChange(sectionIndex, fieldIndex, e)
                        }
                        className="mr-2"
                      />
                      <label>{option}</label>
                    </div>
                  ))}
                {field.fieldType === "search" && (
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search users..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="search-input"
                    />
                    {searchResults.length > 0 && (
                      <div className="dropdown">
                        {searchResults.map((user) => (
                          <div
                            key={user._id}
                            className="dropdown-item"
                            onClick={() => handleUserSelect(user)}>
                            {user?.profilePicture ? (
                              <img
                                src={user.profilePicture}
                                alt="K"
                                className="profile-picture"
                              />
                            ) : (
                              <img
                                src="yellow.png"
                                alt="K"
                                className="profile-picture"
                              />
                            )}
                            <span className="user-name">{user.username}</span>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="chip-container">
                      {selectedUsers.map((user) => (
                        <div
                          key={user._id}
                          className="chip"
                          onClick={() => handleChipRemove(user)}>
                          {user.username}
                          <span className="chip-close-icon">x</span>
                        </div>
                      ))}
                    </div>
                    {/* {isEditable && 
                  <button onClick={confirmSubmission}>Submit</button>} */}
                  </div>
                )}
                {/* {field.fieldType === 'checkbox' && (
                  field.options.map(option => (
                    <div key={option} className="flex items-center mt-1">
                      <input
                        type="checkbox"
                        name={`${field._id}`}
                        value={option}
                        checked={field.response.includes(option)}
                        onChange={(e) => handleChange(sectionIndex, fieldIndex, e)}
                        className="mr-2"
                      />
                      <label>{option}</label>
                    </div>
                  ))
                )} */}
                {field.fieldType === "chips" && (
                  <>
                    <ul>
                      {field?.response.map((rule, index) => (
                        <li key={rule._id || index}>
                          <input
                            type="text"
                            value={rule}
                            className="w-[60%] border border-blue-800 mr-6 mt-2"
                            onChange={(e) =>
                              handleRuleChange(
                                sectionIndex,
                                fieldIndex,
                                index,
                                e.target.value
                              )
                            }
                          />
                          <button
                            onClick={() =>
                              handleDeleteRule(sectionIndex, fieldIndex, index)
                            }>
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>

                    <button
                      type="button"
                      onClick={(e) =>
                        handleAddRule(sectionIndex, fieldIndex, e)
                      }>
                      Add {field.title}
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </form>
      <button
        onClick={() => handleSubmit()}
        className="bg-teal-500 text-white px-4 py-2 rounded-md">
        Update
      </button>
    </div>
  );
};

export default ContestEdit;
