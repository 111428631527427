import React, { useState } from "react";
import { path } from "../../path";
import axios from "axios";


function NotificationModal({ notificationModal = {}, setNotificationModal }) {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const ContestName =
    notificationModal?.contest?.responses?.sections?.[2]?.fields?.[0]?.response || "Contest";

  const handleRequest = async (url) => {
    try {
      setLoading(true);
      const response = await axios.post(url, {
        title,
        body: message,
        contestId: notificationModal?.contest?._id,
      });
      if (response.status === 200) {
        setTitle("");
        setMessage("");
        setNotificationModal(false);
      } else {
        console.error("Failed to send reminder:", response.statusText);
      }
    } catch (error) {
      console.error("Error in sending reminder:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!title || !message) {
      alert("Please fill in all fields.");
      return;
    }

    if (notificationModal.type === "contribution") {
      handleRequest(`${path}/extraction/contributionReminder`);
    } else if (notificationModal.type === "payment") {
      handleRequest(`${path}/extraction/paymentReminder`);
    }
  };

  return (
    <div
      className="absolute bg-black/40 inset-0 flex items-center justify-center z-10"
      onClick={() => setNotificationModal(false)}
    >
      <div
        className="bg-white shadow-sm p-10 w-[50%] rounded-2xl z-20 flex flex-col gap-2"
        onClick={(ev) => ev.stopPropagation()}
      >
        <h1 className="text-xl text-gray-900 font-semibold pb-4 ">{ContestName}</h1>
        <form onSubmit={handleSubmit} className="w-full flex flex-col ">
          <label className="text-gray-600 text-lg" htmlFor="title">Title</label>
          <input
            id="title"
            name="title"
            value={title}
            placeholder="Enter Notification heading"
            onChange={(ev) => setTitle(ev.target.value)}
            className="bg-gray-50 rounded-lg border border-gray-300 px-6 py-2 placeholder:text-gray-500 text-gray-600 outline-none mb-4"
            required
          />

          <label className="text-gray-600 text-lg" htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Enter Notification Message"
            rows={4}
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
            className="bg-gray-50 rounded-lg border border-gray-300 px-6 py-2 placeholder:text-gray-500 text-gray-600 outline-none"
            required
          />

          <button
            type="submit"
            className="bg-blue-500 text-white rounded-xl px-6 py-2 mt-2"
            disabled={loading}
          >
            {loading ? "Sending..." : `Send ${notificationModal.type} Reminder`}
          </button>
        </form>
      </div>
    </div>
  );
}

export default NotificationModal;

