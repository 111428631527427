import axios from "axios";
import React, { lazy, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { path } from "../../path";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import pauseIcon from "./pause-icon.svg";
import EnterPasswordModal from "../Modals/EnterPasswordModal";
import { Link } from "react-router-dom";

const VideoPreview = lazy(() => import("./VideoPreviewPanel"));
function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Parse the ISO string into a Date object
  const date = new Date(dateString);
  if (isNaN(date)) {
    console.log("Invalid date");

    return "";
  }

  const year = date.getFullYear().toString().slice(2);
  const month = months[date.getMonth()];
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? "pm" : "am";
  const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

  return `${day} ${month} ${year}, ${hour12}:${minute < 10 ? "0" + minute : minute} ${period}`;
}

const SubmissionsList = () => {
  const { submissionId, adminAccess, contestName } = useParams();
  const [submission, setSubmission] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null); // State to manage selected video
  const [name, setName] = useState(null); // State to manage selected video
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [modal, setModal] = useState(false);
  const [accessPassword, setAccessPassword] = useState("");
  const [selectItem, setSelectItem] = useState(0);
  const [selectName, setSelectName] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [unpaid, setUnpaid] = useState([]);
  const [paid, setPaid] = useState([]);

  const [displayAll, setDisplayAll] = useState(false);
  const [displayPaid, setDisplayPaid] = useState(false);
  const [displayUnpaid, setDisplayUnpaid] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(() => {
    setDisplayAll(true);
  }, []);

  if (errMsg) {
    console.log("Error nesg ", errMsg);
  }

  const getSubmission = async (submissionId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(
        `${path}/contest/getSubmissionsbyId/${submissionId}`,
        config
      );
      const rawData = response?.data?.result;

      let unpaidSubmissions = rawData?.filter((item) => !item.paid);
      let paidSubmissions = rawData?.filter((item) => item.paid);
      setPaid(paidSubmissions);
      setUnpaid(unpaidSubmissions);
      setSubmission(rawData);
    } catch (error) {
      console.error("FETCH SUBMISSION ERROR", error.message);
    }
  };

  useEffect(() => {
    getSubmission(submissionId);
  }, [submissionId]);

  const info = submission?.map((item) => ({
    id: item._id,
    username: item.user ? item.user.username : "User Not found",
    userid: item.user ? item.user._id : "ID Not found",
    firstName: item.user ? item.user.firstName : "Null",
    lastName: item.user ? item.user.lastName : "404",
    profilePicture: item.user ? item.user.profilePicture : null,
    email: item.user ? item.user.email : null,
    phone: item.user ? item.user.phone : null,
    paymentDetail: item.user ? item.user.paymentDetail : null,
    mediaUrl: item.mediaUrl,
    paidStatus: item.paid,
    boxId: item?.boxId,
    createdAt: item?.createdAt,
    updatedAt: item?.updatedAt,
    team: item?.team,
    contestID: item.user ? item.contest : null,
  }));
  const paidSubmission = paid?.map((item) => ({
    id: item._id,
    username: item.user ? item.user.username : "User Not found",
    userid: item.user ? item.user._id : "ID Not found",
    firstName: item.user ? item.user.firstName : "Null",
    lastName: item.user ? item.user.lastName : "404",
    profilePicture: item.user ? item.user.profilePicture : null,
    email: item.user ? item.user.email : null,
    phone: item.user ? item.user.phone : null,
    paymentDetail: item.user ? item.user.paymentDetail : null,
    mediaUrl: item.mediaUrl,
    paidStatus: item.paid,
    boxId: item?.boxId,
    createdAt: item?.createdAt,
    updatedAt: item?.updatedAt,
    team: item?.team,
  }));
  const unpaidSubmission = unpaid?.map((item) => ({
    id: item._id,
    username: item.user ? item.user.username : "User Not found",
    userid: item.user ? item.user._id : "ID Not found",
    firstName: item.user ? item.user.firstName : "Null",
    lastName: item.user ? item.user.lastName : "404",
    profilePicture: item.user ? item.user.profilePicture : null,
    email: item.user ? item.user.email : null,
    phone: item.user ? item.user.phone : null,
    paymentDetail: item.user ? item.user.paymentDetail : null,
    mediaUrl: item.mediaUrl,
    paidStatus: item.paid,
    boxId: item?.boxId,
    createdAt: item?.createdAt,
    updatedAt: item?.updatedAt,
    team: item?.team,
  }));

  const deleteFunction = async (id) => {
    await axios.post(`${path}/contest/submissionDelete`, {
      submissionId: id,
    });
  };

  function alertBox(id, name, access) {
    let adminAcc = access;
    // Ensure that `adminAcc` is a boolean
    if (typeof adminAcc === "string") {
      adminAcc = JSON.parse(adminAcc.toLowerCase());
    }
    if (adminAcc === false) {
      if (window.confirm("Are you sure you want to delete this item?")) {
        deleteFunction(id)
          .then(() => {
            setTimeout(() => {
              alert("Deleted successfully");
              window.location.reload();
            }, 200);
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            alert("Failed to delete the item. Please try again.");
          });
      }
    } else {
      setModal(true);
      setSelectItem(id);
      setSelectName(name);
    }
  }

  const handleAdminAccess = async (pass) => {
    try {
      const response = await axios.post(`${path}/checkAdminAccess`, {
        email: user.email,
        password: pass,
      });
      if (response.data.status === "success") {
        setModal(false);
        deleteFunction(selectItem).then(
          setTimeout(() => {
            alert("Deleted successfully");
            window.location.reload();
          }, 200)
        );
      }
      if (response.data.status === "failed") {
        setErrMsg(response.data.message);
        alert("Wrong password, enter again");
        setAccessPassword("");
      }
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  const UserCard = ({ item }) => {
    return (
      <div
        key={item.id}
        className={`$ flex justify-between px-3 space-x-1 xl:space-x-2 text-[#707C97] py-3 border rounded-lg border-transparent border-b-gray-400
        ${(name === item?.username && !item?.team) || (item?.team && detail?.id === item.id) ? "bg-gray-200" : null}
        `}>
        <div className="flex space-x-4">
          <div>
            {item.profilePicture ? (
              <img
                className="h-11 w-11 border rounded-full"
                src={item.profilePicture}
                alt="Profile Image"
              />
            ) : item?.team?.teamAvatar?.imageUrl ? (
              <img
                className="h-11 w-11 border rounded-full"
                src={item.team?.teamAvatar?.imageUrl}
                alt="Profile Image"
              />
            ) : item?.team && !item?.team?.teamAvatar?.imageUrl ? (
              <img
                className="h-11 w-11 border rounded-full"
                src="https://cdn.iconscout.com/icon/premium/png-512-thumb/team-2265497-1898434.png?f=webp&w=256"
                alt="Profile Image"
              />
            ) : (
              <Avatar />
            )}
          </div>
          <div className="flex flex-col px-3">
            {item?.username && !item.team ? (
              <p className="font-bold text-[15px] sm:text-sm">
                {item.firstName} {item.lastName}
              </p>
            ) : (
              <p className="font-bold text-[15px] sm:text-sm">
                {item.team?.name}
              </p>
            )}
            <p>{formatDate(item?.createdAt)}</p>
          </div>
        </div>

        <div className="pr-10 block lg:flex space-x-0 lg:space-x-2 space-y-2 lg:space-y-0 items-center">
          {item.paidStatus && (
            <h2 className="f  bg-[#008080] rounded-xl h-6 w-10 text-center justify-center p-1 text-xs text-white  ">
              Paid
            </h2>
          )}

          <button
            className="border bg-[#F6F5FA] border-[#008080] transition duration-300 ease-in-out hover:scale-90 hover:cursor-pointer w-24 px-3 h-8 py-1 rounded-xl cursor-pointer flex items-center space-x-2"
            onClick={() => {
              setSelectedVideo(item.mediaUrl);
              setDetail(item);
              setName(item.username);
            }}>
            {item?.mediaUrl && (
              <img src={pauseIcon} className=" h-3 lg:h-4 " alt="Pause Icon" />
            )}
            {item?.mediaUrl ? (
              <p className="text-[#008080] text-sm lg:text-sm">View</p>
            ) : (
              <p className="text-red-700 text-sm lg:text-sm">Upload ⬆️</p>
            )}
          </button>

          <button
            className="ml-2 border border-[black] w-24 px-3 h-8 py-1 space-x-1 bg-[#F6F5FA] hover:bg-red-600 transition duration-300 ease-in-out hover:scale-110 hover:cursor-pointer rounded-xl cursor-pointer flex items-center text-red-700 hover:text-white"
            onClick={() =>
              alertBox(
                item?.id,
                item.firstName + " " + item.lastName,
                adminAccess
              )
            }>
            <img
              src="https://cdn-icons-png.flaticon.com/512/3405/3405244.png"
              className="h-5"
              alt="Delete Icon"
            />
            <p className="text-sm lg:text-[14px]">Delete</p>
          </button>
        </div>
      </div>
    );
  };

  // const withPaid = (Component) => {
  //   return function EnhancedCard({ item }) {
  //     return (
  //       <div>
  //         {/* {item.paidStatus && (
  //           <div className="flex mt-10 bg-[#008080] rounded-xl w-10 text-center justify-center p-1 absolute text-xs text-white  left-3">
  //             Paid
  //           </div>
  //         )} */}
  //         <Component item={item} />
  //       </div>
  //     );
  //   };
  // };

  // const EnhancedItem = withPaid(UserCard);

  const handleAll = () => {
    setDisplayAll(true);
    setDisplayPaid(false);
    setDisplayUnpaid(false);
  };
  // const handlePaid = () => {
  //   setDisplayAll(false);
  //   setDisplayPaid(true);
  //   setDisplayUnpaid(false);
  // };
  const handleUnpaid = () => {
    setDisplayAll(false);
    setDisplayPaid(false);
    setDisplayUnpaid(true);
  };

  return (
    <div className="">
      {modal && (
        <EnterPasswordModal
          handleAdminAccess={handleAdminAccess}
          setAccessPassword={setAccessPassword}
          setModal={setModal}
          accessPassword={accessPassword}
          message={`delete ${selectName} submission`}
        />
      )}

      <div className="flex flex-1 justify-center md:hidden">
        {selectedVideo && (
          <VideoPreview
            video={selectedVideo}
            username={name}
            setVideo={setSelectedVideo}
            detail={detail}
            setDetail={setDetail}
          />
        )}
      </div>
      <div className="flex h-screen flex-1 bg-[#F6F5FA] ">
        <div className="flex flex-col flex-1  px-6 py-2 w-1/2 ">
          <div className="flex items-center my-3 font-bold text-2xl">
            <Link to={"/marketingAdmin"}>
              <div className="flex items-center ">
                <img
                  src="https://w7.pngwing.com/pngs/848/762/png-transparent-computer-icons-home-house-home-angle-building-rectangle-thumbnail.png"
                  className="h-6 mr-3"
                  alt="home"
                />
                <h1>Home</h1>
              </div>
            </Link>
            <div className="flex mx-auto">
              <p className="mr-3">{contestName} </p>
              {info?.length && <p>({info.length})</p>}
            </div>
          </div>

          {!info && (
            <div className="flex justify-center items-center">
              <p className="text-3xl text-red-800">No Submissions yet</p>
            </div>
          )}
          <div className="flex items-center px-2 py-1 space-x-3">
            <button
              onClick={handleAll}
              className={`${displayAll && "text-[#008080]"}`}>
              All
            </button>
            {/* <button
              onClick={handlePaid}
              className={`${displayPaid && "text-[#008080]"}`}>
              Paid [{paidSubmission?.length}]
            </button> */}
            <button
              onClick={handleUnpaid}
              className={`${displayUnpaid && "text-[#008080]"}`}>
              Unpaid [{unpaidSubmission?.length}]
            </button>
          </div>
          <div className="border   border-transparent  rounded-xl bg-[#FFFFFF] h-[38rem]  p-3 overflow-hidden overflow-y-auto">
            {displayAll &&
              info?.map((item) => <UserCard item={item} key={item.id} />)}
            {displayPaid &&
              paidSubmission?.map((item) => (
                <UserCard item={item} key={item.id} />
              ))}
            {displayUnpaid &&
              unpaidSubmission?.map((item) => (
                <UserCard item={item} key={item.id} />
              ))}
          </div>
        </div>
        <div className="hidden md:contents">
          <VideoPreview
            video={selectedVideo}
            username={name}
            setVideo={setSelectedVideo}
            detail={detail}
            setDetail={setDetail}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmissionsList;
