import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { path } from "../../path";
import { toast } from "react-toastify";

const DataExtractionModel = ({ open, toggle }) => {
  const [skills, setSkills] = useState([]);
  const [showSkills, setShowSkills] = useState(true);

  useEffect(() => {
    const getAllSkills = async () => {
      const res = await axios.get(`${path}/searchSkill`);
      setSkills(res.data.data);
    };
    getAllSkills();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    const category = event.target[0].value;
    const skill = event.target[1]?.value?.trim();
    const subskill = event.target[2]?.value?.trim();
    let link;

    switch (category) {
      case "registeredUser": {
        if (!skill) {
          toast.error("Skill cannot be empty!");
          return;
        }
        const encodedSkill = encodeURIComponent(skill);
        const encodedSubSkill = encodeURIComponent(subskill);
        const encodedFileName = encodeURIComponent(
          `unique-users-registered-${skill}${subskill ? "-" + subskill : ""}`
        );
        link = `${path}/extraction/getUsersInContestBySkill?skill=${encodedSkill}${subskill ? "&subSkill=" + encodedSubSkill : ""}&fileName=${encodedFileName}`;
        break;
      }
      case "submittedUser": {
        if (!skill) {
          toast.error("Skill cannot be empty!");
          return;
        }
        const encodedSkill = encodeURIComponent(skill);
        const encodedSubSkill = encodeURIComponent(subskill);
        const encodedFileName = encodeURIComponent(
          `unique-users-submitted-${skill}${subskill ? "-" + subskill : ""}`
        );
        link = `${path}/extraction/getUsersSubmittedInSkill?skill=${encodedSkill}${subskill ? "&subSkill=" + encodedSubSkill : ""}&fileName=${encodedFileName}`;
        break;
      }
      case "contributionsData": {
        link = `${path}/extraction/getUserContributionOnSkill`;
        break;
      }
      default: {
        toast.error("Invalid category");
        break;
      }
    }

    if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Modal
      open={open}
      onClose={toggle}
      className="flex items-center justify-center">
      <div className="bg-white p-4 rounded">
        <div className="flex flex-row justify-between mb-2">
          <h3>Data Extraction</h3>
          <CloseIcon className="cursor-pointer" onClick={toggle} />
        </div>
        <form onSubmit={submitHandler}>
          <div className="mb-4 gap-1">
            <div className="mb-2">
              <label htmlFor="category" className="mb-1  text-[15px]">
                Category
              </label>
              <select
                id="category"
                name="category"
                onChange={(event) =>
                  setShowSkills(event.target.value !== "contributionsData")
                }
                className="w-full capitalize p-1 border border-[#008080] rounded">
                <option value="registeredUser" className="text-wrap">
                  Registered user in contests with given skill and subskill
                </option>
                <option value="submittedUser">
                  Submitted user in contests with given skill and subskill
                </option>
                <option value="contributionsData">
                  Data regarding contributions
                </option>
              </select>
            </div>
            {showSkills && <>
                <div className="mb-2">
                    <label htmlFor="skill" className="mb-1 text-[15px]">
                        Skill
                    </label>
                    <input
                        list="skillList"
                        name="skill"
                        id="skill"
                        className="w-full p-1 border border-[#008080] rounded"
                    />
                    <datalist id="skillList" className="capitalize h-10">
                    {skills.map((skill) => (
                        <option key={skill._id} value={skill.name}>
                        {skill.name}
                        </option>
                    ))}
                    </datalist>
                </div>
                <div>
                    <label htmlFor="subskill" className="mb-1  text-[15px]">
                        Sub Skill
                    </label>
                    <input
                        className="w-full p-1 border border-[#008080] rounded"
                        name="subskill"
                        id="subskill"
                        type="text"
                    />
                </div>
            </>}
          </div>
          <button
            type="submit"
            className="bg-[#008080] text-white px-2 py-1 rounded flex-wrap">
            Download
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default DataExtractionModel;
