import React from "react"
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { path } from "../../path";

const ContestOptionsModal = (props) => {
    const { open, toggle, setNotificationModal, contest } = props;

    const handlePaymentReminder = () => {
        toggle();
        setNotificationModal({type:"payment",contest});
    }

    const handleContributionReminder = () => {
        toggle();
        setNotificationModal({type:"contribution",contest});
    }

    const handleRegisteredUserData = async () => {
        try {
            const contestTitle = contest?.responses?.sections[2]?.fields[0]?.response?.trim()?.split(" ")?.join("-")?.toLowerCase() || "";
            const encodedContestTitle = encodeURIComponent(`registered-users-${contestTitle}`);
            const link = `${path}/contest/dataExtraction?contestId=${encodeURIComponent(contest._id)}&fileName=${encodedContestTitle}`;
            
            window.open(link, "_blank", "noopener,noreferrer")
        } catch (error) {
            toast.error("Error getting data, please try again!")
            console.error("Error data extraction"+JSON.stringify(error));
        }
    }
    
    const handleUserDistributionData = async () => {
        try {
            const contestTitle = contest?.responses?.sections[2]?.fields[0]?.response?.trim()?.split(" ")?.join("-")?.toLowerCase() || "";
            const encodedContestTitle = encodeURIComponent(`users-distribution-${contestTitle}`);
            const link = `${path}/extraction/getNewAndExistingContestUsers?contestId=${encodeURIComponent(contest._id)}&fileName=${encodedContestTitle}`;

            window.open(link, "_blank", "noopener,noreferrer")
        } catch (error) {
            toast.error("Error getting data, please try again!")
            console.error("Error data extraction"+JSON.stringify(error));
        }
    }

    return <Modal
        open={open}
        onClose={toggle}
        className="flex items-center justify-center"
    >
        <div className="bg-white p-4 rounded-lg">
            <div className="flex flex-row justify-between mb-2">
                <h3>Contest Options</h3>
                <CloseIcon className="cursor-pointer" onClick={toggle} />
            </div>
            <div className="flex flex-col gap-2">
                <div>
                    <h4>Notifications</h4>
                    <div className=" flex items-center gap-1">
                        <button
                            onClick={handleContributionReminder}
                            className="bg-gray-50 border border-gray-300 px-2 py-1 rounded-lg text-[12px] flex-wrap">
                            Contribution  Reminder
                        </button>
                        <button
                            onClick={handlePaymentReminder}
                            className="bg-gray-50 border border-gray-300 px-2 py-1 rounded-lg text-[12px] flex-wrap">
                            Payment  Reminder
                        </button>
                    </div>
                </div>
                <div>
                    <h4>Data extraction</h4>
                    <div className=" flex items-center gap-1">
                        <button 
                            className="bg-gray-50 border border-gray-300 px-2 py-1 rounded-lg text-[12px] flex-wrap"
                            onClick={handleRegisteredUserData}
                        >
                            Registered User Data
                        </button>
                        <button 
                            className="bg-gray-50 border border-gray-300 px-2 py-1 rounded-lg text-[12px] flex-wrap"
                            onClick={handleUserDistributionData}
                        >
                            User Distribution Data
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}

export default ContestOptionsModal;